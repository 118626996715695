<template>
  <div v-if="paid_bundle" class="store-payment">
    <div class="store-payment-completed">
      <div class="store-backdrop">
        <img
          style="width: 62.5vw; height: 58vw;min-width: 835px;min-height: 900px;"
          src="@/assets/Store_complete_box.png"
        />
        <img
          style="position: absolute; left: 23%;height: 13.5%;"
          src="@/assets/Store_check.png"
        />
        <img
          style="position: absolute;right: 40.5%;top: 12%;height: 82%;"
          src="@/assets/Store_ho_divider.png"
        />
      </div>
      <div class="store-payment-container">
        <div class="payment-left">
          <div class="left-top-header">
            <h1 style="font-size: 28px">จองสำเร็จ!</h1>
            <div style="display: flex; align-items: center;">
              <span>หมายเลขการชำระเงิน :</span>
              <div class="payment-ref">{{ refno }}</div>
            </div>
          </div>
          <div class="left-content">
            <div
              style="position:relative;height: 19vw; width: 100%; min-height: 273px;"
            >
              <img
                style="width: 100%;height: 100%; object-fit: cover; border-radius: 7px"
                :src="paid_bundle.image"
              />
              <img
                class="promo-preview-logo"
                :src="paid_bundle.provider.logo"
              />
            </div>
            <div
              style="display: flex; justify-content: space-between; align-items:center; margin: 1rem 0;"
            >
              <div style="display: flex; flex-direction: column">
                <span style="font-size: 12px">{{
                  paid_bundle.provider.fullname
                }}</span>
                <span class="name" style="font-size: 18px">{{
                  paid_bundle.name
                }}</span>
              </div>
              <div class="preview-amount">{{ bundles.length }}x</div>
            </div>
            <div class="bundle-detail">
              <span style="font-size: 12px; color: #A7ABBE;"
                >รายละเอียดคูปองส่วนลด</span
              >
              <div style="    overflow: scroll; height: 132px;">
                <div v-for="bundle in bundles" :key="bundle.key">
                  <div
                    style="display: flex; align-items:center;margin: 0.5rem 0rem"
                  >
                    <div class="bundle-code">
                      <div
                        style="background-color: #D60326;color: white;
                                                padding: 0.5rem;border-radius: 15px 0px 0px 15px;"
                      >
                        รหัส
                      </div>
                      <div
                        style="background-color: #F5F6F7;
                                                padding: 0.5rem 1.5rem;border-radius: 0px 15px 15px 0px;"
                      >
                        {{ bundle.redeem_code }}
                      </div>
                    </div>
                    <span>หมดอายุ : {{ format_date(bundle.expire_end) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="bundle-footer">
              <span style="font-size: 12px; color: #A7ABBE;">เงื่อนไข</span>
              <ol style="padding-inline-start: 1rem;">
                <li>
                  แจ้งเตือนรหัสคูปองส่วนลด -
                  หลังจากชำระเงินเสร็จสิ้นลูกค้าจะได้รับ Email
                  แจ้งเตือนพร้อมรหัสคูปองส่วนลด
                </li>
                <li>
                  แสดงคูปองส่วนลดให้กับพนักงาน - ลูกค้าสามารถใช้คูปองส่วนลด
                  และแสดงให้พนังงานที่สนามได้ทันที
                  หรือสามารถใช้ส่วนลดในการจองผ่านแอปพลิเคชัน matchday
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="payment-right">
          <div class="right-content">
            <div style="align-self: flex-start;">
              <div class="right-header">
                <i class="fas fa-file-alt"></i>
                <span style="margin-left: 0.5rem">รายละเอียดผู้จอง</span>
              </div>
              <div class="right-info" style="height: 127px">
                <div class="info-span">
                  <span style="color: #8E91A0">ชื่อผู้จอง</span>
                  <span>{{ user.fullname }}</span>
                </div>
                <div class="info-span">
                  <span style="color: #8E91A0">อีเมล</span>
                  <span>{{ user.email }}</span>
                </div>
                <div class="info-span">
                  <span style="color: #8E91A0">หมายเลขโทรศัพท์ </span>
                  <span>{{ addDashes(user.phone_number) }}</span>
                </div>
              </div>
            </div>
            <div style="align-self: flex-start;">
              <div class="right-header">
                <i class="fas fa-coins"></i>
                <span style="margin-left: 0.5rem"
                  >รายละเอียดการชำระเงิน (ชำระแล้ว)</span
                >
              </div>
              <div class="right-info" style="height: 202px">
                <div
                  style="height: 34%;display: flex;flex-direction: column;justify-content: space-between;"
                >
                  <div class="info-span">
                    <span style="color: #8E91A0">ราคาเต็ม</span>
                    <span
                      >{{
                        parseFloat(paid_bundle.price_original) * bundles.length
                      }}
                      ฿</span
                    >
                  </div>
                  <div class="info-span">
                    <div>
                      <img src="@/assets/discount_tag.png" />
                      <span style="color: #8E91A0;margin-left: 0.25rem"
                        >ส่วนลดจาก matchday</span
                      >
                    </div>
                    <span style="color: #D60326"
                      >-
                      {{
                        parseFloat(paid_bundle.price_original) *
                          bundles.length -
                          paid_bundle.price * bundles.length
                      }}
                      ฿</span
                    >
                  </div>
                </div>
                <div
                  class="info-span"
                  style="padding-top: 1.5rem;border-top:1px solid rgba(0, 0, 0, 0.2);"
                >
                  <div style="display: flex; flex-direction: column">
                    <span>ราคาสุดท้าย </span>
                    <span style="color: #8E91A0;font-size: 12px"
                      >รวมภาษี และค่าใช้จ่ายอื่นๆ</span
                    >
                  </div>
                  <span
                    style="font-size: 28px;display:flex;align-items: baseline"
                    >{{ total_price }}
                    <p style="font-size: 15px;margin-left:0.1rem">฿</p></span
                  >
                </div>
              </div>
            </div>
          </div>
          <button
            style="background-color: #009881;width: 132px; height: 41px"
            @click="back_to_home()"
            type="button"
            class="btn btn-success"
          >
            กลับสู่หน้าหลัก
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else style="width: 100%;display: flex;justify-content: center;">
    <img style="margin: auto" :src="loading_img_url" />
  </div>
</template>

<script>
import moment from "moment";
import StoreService from "../resources/store_service";

export default {
  name: "StorePaymentComplete",
  data() {
    return {
      loading_img_url: 'https://cdn.discordapp.com/attachments/780994260471775272/983601360404615178/S__41820187.gif',
      paid_bundle: null,
      total_price: 0,
      original_price: 0,
      refno: "Md123456",
      bundles: [
        { redeem_code: "MATCHDAY01", expire_end: "2021-10-08 00:00:00" },
        { redeem_code: "MATCHDAY01", expire_end: "2021-10-08 00:00:00" },
        { redeem_code: "MATCHDAY01", expire_end: "2021-10-08 00:00:00" }
      ],
      user: {
        fullname: "ฐิตวันต์ หิรัณย์อุฬาร",
        email: "thitawan@gmail.com",
        phone_number: "0895555555"
      }
    };
  },
  methods: {
    get_bundle_data(payment) {
      this.total_price = parseFloat(payment.total);
      this.refno = payment.refno;
      this.bundles = payment.asset_bundle.map(asset_bundle => {
        return {
          redeem_code: asset_bundle.redeem_code,
          expire_end: asset_bundle.expire_end ? asset_bundle.expire_end : null
        };
      });
      this.user = payment.asset_bundle[0].asset.user;
      this.paid_bundle = payment.asset_bundle[0].bundle;

      this.clear_bundle_refno();
    },
    format_date(date) {
      return date
        ? `${moment(date, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          )} นับจากวันที่ซื้อ`
        : "ไม่จำกัด";
    },
    back_to_home() {
      this.$router.push("/");
    },
    clear_bundle_refno() {
      localStorage.removeItem("bundle_refno");
    },
    addDashes(phone) {
      return phone.slice(0, 3) + "-" + phone.slice(3, 6) + "-" + phone.slice(6);
    }
  },
  async mounted() {
    const paid_bundle = await new StoreService().get_paid_bundle(this.$route.params.refno_id);
    this.get_bundle_data(paid_bundle.payments[0]);
  }
};
</script>

<style scoped>
.name {
  width: 31vw;
  text-overflow: ellipsis;
  cursor: pointer;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
}
.name:hover {
  overflow: visible;
  white-space: normal;
  height: auto; /* just added this line */
}
.info-span {
  display: flex;
  justify-content: space-between;
}
.info-row {
  margin: 0.5rem 0.5rem;
}
.right-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f6f7;
  padding: 1rem;
  font-size: 14px;
  border: 1px solid #dfe1ea;
  border-radius: 7px;
  margin: 1rem 0rem;
  margin-bottom: 2rem;
}
.right-header {
  font-size: 18px;
  color: #1379e6;
}
.payment-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 325px;
  height: 90%;
}
.bundle-footer {
  font-weight: 300;
  font-size: 12px;
  margin: 0;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.bundle-code {
  display: flex;
  margin-right: 0.5rem;
}
.bundle-detail {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0rem;
}
.preview-amount {
  text-align: center;
  height: 43px;
  line-height: 2.6rem;
  width: 43px;
  color: #d60326;
  font-size: 22px;
  border: 1px solid #ffccd4;
  border-radius: 11px;
}
.left-top-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.left-content {
  width: 100%;
  margin: 1rem;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0;
}
.payment-left {
  width: 58%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-ref {
  margin-left: 0.5rem;
  font-size: 14px;
  color: #1379e6;
  border: 1px solid #e3f3ff;
  border-radius: 47px;
  width: fit-content;
  padding: 0.5rem 1rem;
}
.store-payment-container {
  position: absolute;
  bottom: 5%;
  display: flex;
  width: 93%;
  height: 79%;
  justify-content: space-between;
}
.store-backdrop {
  width: fit-content;
  height: fit-content;
  position: relative;
  margin: 1rem;
}
.store-payment-completed {
  width: fit-content;
  height: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 5vw;
}
.store-payment {
  background-color: #f7f7f7;
  height: fit-content;
  padding-bottom: 5vw;
}
.promo-preview-logo {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  object-fit: cover;
  border-radius: 100%;
  width: 44px;
  height: 44px;
}
</style>
